<template>
  <div class="zone">
    <div class="header">
      <h2>Découvrez tous nos plats de la zone</h2>
      <h1>{{ slug }}</h1>
    </div>
    <div class="card-container">
      <div v-for="c in meals" :key="c.id">
        <MealCard :meal="c"/>
      </div>
    </div>
  </div>
</template>

<script>
import MealCard from '@/components/MealCard.vue'

export default {
  name: 'Zone',
  components: {
    MealCard
  },
  props: {
    slug: {
      type: String
    }
  },
  data () {
    return {
      meals: null
    }
  },
  mounted () {
    fetch('https://www.themealdb.com/api/json/v1/1/filter.php?a=' + this.slug)
      .then(response => response.json())
      .then(response => {
        if (response.meals != null) this.meals = response.meals
        else throw new Error('Bad Zone')
      })
      .catch(e => {
        this.$router.push({ path: '/404' })
      })
  }
}
</script>
